
/* NOT IN USE */
/* NOT IN USE */
/* NOT IN USE */

/*
async function safeImport(path) {
    let result, error;
    try {
        result = await import(path);
    } catch (err) {
        error = err;
    }
    return [ result, error ];
}

for (let route of routes) {
    let component, error;
    if (route.meta.async) {
        component = () => import(`${route.meta.articleID}/App.vue`);
        route.component = component;
    } else {
        [ component, error ] = await safeImport(`${route.meta.articleID}/App.vue`);
        route.component = async () => component;
    }
    //let [ component, error ] = await safeImport(`${route.meta.articleID}/App.vue`);
    if (!component) {
        console.warn(`Route error\n\nArticle: ${route.meta.articleID}\nRoute: ${route.path}\n\n${error}`);
        route.meta.error = error;
        route.component = async () => ErrorPage;
    }
}
*/

let routes;

async function safeImport(path) {
    let result, error;
    try {
        result = await import(path);
    } catch (err) {
        error = err;
    }
    return [ result, error ];
}

/*
async function initRoute(route) {

}
*/

export async function preload(path) {
    const route = routes.filter(e => e.path === path)[0];
    let component = await route.component();
    route.component = () => component;
}

export async function initRoutes(r) {
    routes = r;
    let index = 0;
    for (let route of r) {
        index++;
        route.component = () => import(`${route.meta.appID}/App.vue`);
        /*
        const [ component, error ] = await safeImport(`${route.meta.appID}/App.vue`);
        route.component = async () => component;
        */
        //route.component = import(`${route.meta.appID}/App.vue`);
    }
    /*
    await preload("/activity/:id");
    await preload("/activity/:id");
    */
}
